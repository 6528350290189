@charset "utf-8";

/*!
 * 版本：MYUI Copyright © 2019
 * 作者：QQ726662013版权所有
 * 官网：https://www.mytheme.cn
 */

/* body */

body,
html {
  width: 100%;
}

body {
  font-size: 14px;
  line-height: 140%;
}

body,
ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
table,
td,
img,
div,
tr {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

input,
select {
  font-size: 12px;
  vertical-align: middle;
  border: none;
}

ul,
li {
  list-style-type: none;
}

img {
  border: 0 none;
}

p {
  margin-bottom: 10px;
}

/* container */

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.container {
  position: relative;
  margin-right: auto;
  margin-left: auto;
}

.row {
  position: relative;
  margin: 0;
  padding: 0;
}

.container:before,
.container:after,
.row:before,
.row:after,
.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
  clear: both;
}

/* text */

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-bottom: 10px;
}

a,
button {
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

button {
  border: 0;
}

button:hover,
input.btn {
  cursor: pointer;
}

a:focus,
a:hover,
a:active {
  text-decoration: none;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-overflow {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}

.content {
  font-size: 16px;
  line-height: 30px;
  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
}

/* font */

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-25 {
  font-size: 24px;
}

.font-40 {
  font-size: 40px;
}

.font-bold {
  font-weight: 700;
}

/* img */

img {
  border: 0;
  vertical-align: middle;
}

.img-circle {
  border-radius: 50%;
}

.img-rounded {
  border-radius: 5px;
}

.img-thumbnail {
  padding: 5px;
  border-radius: 5px;
}

.img-responsive {
  max-width: 100%;
}

.img-thumb {
  width: 220px;
}

.content img {
  display: block;
  max-width: 100%;
}

/* form */

select {
  cursor: pointer;
  border: none;
  outline: none;
}

input,
textarea {
  outline: medium none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input.form-control,
input.btn {
  outline: 0px;
  -webkit-appearance: none;
}

input[type="checkbox"] {
  vertical-align: -2px;
}

.form-control {
  display: block;
  width: 100%;
  height: 35px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 20px;
  border-radius: 4px;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

textarea.form-control {
  height: auto;
  padding: 10px;
}

.form-control.verify {
  width: 90px;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
}

/* padding */

.pb10 {
  padding-bottom: 10px;
}

.pt10 {
  padding-top: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pt15 {
  padding-top: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pt20 {
  padding-top: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pt30 {
  padding-top: 30px;
}

/* padding */

.mb10 {
  margin-bottom: 10px;
}

.mt10 {
  margin-top: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mt15 {
  margin-top: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt20 {
  margin-top: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt30 {
  margin-top: 30px;
}

/* line */

.spot {
  display: inline-block;
  margin-right: 5px;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid;
}

.split-line {
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;
  width: 1px;
  height: 14px;
  vertical-align: -2px;
}

.top-line,
.top-line-dot,
.bottom-line,
.bottom-line-dot {
  position: relative;
}

.top-line:before,
.top-line-dot:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 1px;
}

.bottom-line:after,
.bottom-line-dot:before {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 1px;
}

.top-line:before {
  border-top: 1px solid;
}

.bottom-line:after {
  border-bottom: 1px solid;
}

.top-line-dot:before {
  border-top: 1px dotted;
}

.bottom-line-dot:before {
  border-bottom: 1px dotted;
}

/* border */

.border {
  border: 1px solid;
}

.border-2x {
  border: 2px solid;
}

.border-3x {
  border: 3px solid;
}

.border-4x {
  border: 4px solid;
}

.border-5x {
  border: 5px solid;
}

/* badge */

.badge {
  display: inline-block;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 2px;
  font-size: 12px;
}

.badge-radius {
  border-radius: 50%;
}

/* btn */

.btn {
  display: inline-block;
  padding: 8px 30px;
  font-size: 12px;
}

.btn.btn-xs {
  padding: 4px 15px;
}

.btn.btn-sm {
  padding: 6px 20px;
}

.btn.btn-md {
  padding: 8px 25px;
}

.btn.btn-lg {
  padding: 12px 30px;
}

.btn.btn-block {
  display: block;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}

.btn.disabled {
  cursor: not-allowed;
}

.btn-warm {
  background-color: #ff9900;
  background: linear-gradient(to right,#ff9900 0,#ff9f16 100%);
  box-shadow: 0 5px 10px rgba(255,111,0,.25);
}

/* nav */

.nav {
  position: relative;
}

.nav > li {
  float: left;
}

.nav > li.active > a {
  border-bottom: 2px solid;
}

.nav-head > li,
.nav-tabs > li {
  margin-top: 10px;
  margin-left: 30px;
}

.nav-head > li > a {
  padding-bottom: 8px;
  font-size: 16px;
}

.nav-tabs > li > a {
  padding-bottom: 10px;
}

.nav-tabs > li .dropdown-box {
  margin-left: -50px;
  padding-top: 0;
}

.nav-tabs > li .dropdown-box .item {
  width: 100px;
  text-align: center;
}

.nav-tabs > li .dropdown-box .item li {
  padding: 5px;
}

.nav-tag > li {
  margin-left: 10px;
}

.nav-tag > li > a {
  display: inline-block;
  padding: 0 10px;
  height: 25px;
  line-height: 23px;
  font-size: 14px;
  border-radius: 20px;
}

.nav-text > li {
  line-height: 30px;
}

.nav-text > li.active > a {
  color: #f80;
}

.nav-page {
  margin-left: 10px;
}

.nav-page > li {
  margin-left: 5px;
}

.nav-page > li > a {
  display: inline-block;
  font-size: 14px;
  padding: 0 6px;
  height: 25px;
  line-height: 23px;
}

.nav-page > li:first-child {
  margin-left: 0;
}

.nav-page > li:first-child > a {
  border-radius: 4px 0 0 4px;
}

.nav-page > li:last-child > a {
  border-radius: 0 4px 4px 0;
}

/* pic-tag */

.pic-tag {
  position: absolute;
  z-index: 99;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 2px;
}

.pic-tag-top {
  top: 5px;
  left: 5px;
}

.pic-tag-left {
  bottom: 5px;
  left: 5px;
}

.pic-tag-bottom {
  bottom: 5px;
  right: 5px;
}

.pic-tag-right {
  top: 5px;
  right: 5px;
}

.pic-tag-h {
  left: 0;
  top: 0;
  padding: 2px 8px;
  border-radius: 0 0 8px 0;
}

.pic-tag-lg {
  padding: 4px 10px;
}

.pic-text {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 99;
  bottom: 0;
  left: 0;
  padding: 5px 10px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}

.pic-text-silde {
  padding-bottom: 20px;
  font-size: 14px;
}

.pic-text-lg {
  padding: 8px 20px;
  font-size: 14px;
}

.pic-title-top {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px 10px;
  font-size: 12px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pic-title-bottom {
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px 10px;
  font-size: 12px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* qrcode */

.qrcode-box {
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
}

.qrcode-box .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12.5px;
  margin-left: -12.5px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0,0,0,.1);
}

/* dropdown */

.dropdown {
  position: relative;
}

.dropdown-box {
  display: none;
  position: absolute;
  z-index: 99;
}

.dropdown-box.top {
  bottom: 100%;
  padding-bottom: 10px;
  left: 50%;
  margin-left: -80px;
}

.dropdown-box.bottom {
  top: 100%;
  padding-top: 10px;
  left: 50%;
  margin-left: -80px;
}

.dropdown-box.left {
  left: 100%;
  padding-left: 10px;
  bottom: 0;
}

.dropdown-box.right {
  right: 100%;
  padding-right: 10px;
  bottom: 0;
}

.dropdown-box .item {
  padding: 10px;
  width: 160px;
  border-radius: 2px;
}

.dropdown-hover {
  position: relative;
}

.dropdown-hover:hover .dropdown-box {
  display: block;
}

.open>.dropdown-box {
  display: block;
}

/* layout */

.myui-layout {
  position: relative;
}

/* panel */

.myui-panel {
  position: relative;
}

.myui-panel-box.active {
  padding: 0;
}

/* panel-head */

.myui-panel__head {
  position: relative;
  height: 30px;
}

.myui-panel__head .title {
  float: left;
  display: inline-block;
  margin: 0;
  padding-right: 10px;
  line-height: 30px;
}

.myui-panel__head .title img {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: -5px;
}

.myui-panel__head .title,
.myui-panel__head .title a {
  font-size: 24px;
}

.myui-panel__head .more {
  line-height: 30px;
}

.myui-panel__head .operate {
  position: relative;
  padding-bottom: 5px;
}

.myui-panel__head .operate.open .dropdown-menu {
  display: block;
}

.myui-panel__head.active {
  height: 42px;
}

/* vod-list */

.myui-vodlist__thumb {
  display: block;
  position: relative;
  background-position: 50% 50% !important;
  background-size: cover !important;
  overflow: hidden;
  box-shadow: 0 1px 2px rgba(0,0,0,.1);
}

.myui-vodlist__thumb.picture {
  background: none;
  overflow: hidden;
}

.myui-vodlist__thumb.picture img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.myui-vodlist__thumb .play {
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2) url("img/play.png") center no-repeat;
  background-color: rgba(0,0,0,.2);
  -webkit-transition: all ease-out .35s;
  transition: all ease-out .35s;
}

.myui-vodlist__thumb .play-v {
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.2);
  -webkit-transition: all ease-out .35s;
  transition: all ease-out .35s;
}

.myui-vodlist__thumb .play.active {
  opacity: 1;
}

.myui-vodlist__thumb:hover .play,
.myui-vodlist__thumb:hover .play-v {
  opacity: 1;
}

.myui-vodlist__detail.active {
  padding: 0 10px 10px;
}

.myui-vodlist__detail .title {
  margin: 10px 0 0;
  font-size: 15px;
  font-weight: 700;
}

.myui-vodlist__detail .title.active {
  font-size: 16px;
  margin-bottom: 10px;
}

.text-xcorlo {
  color: #e8e8e8!important;
}

.myui-vodlist__detail .text {
  min-height: 19px;
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 5px;
}

.myui-vodlist__detail .text-title {
  font-size: 14px;
  line-height: 18px;
  margin: 11px 0 0;
  height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

/* text-list */

.myui-vodlist__text li a {
  display: block;
}

.myui-vodlist__text.active li a {
  padding: 0 0 15px;
}

.myui-vodlist__text.active li:first-child a {
  padding-top: 0;
}

.myui-vodlist__text.striped li {
  padding: 10px 0 10px;
}

.myui-vodlist__text.striped li a {
  display: inline-block;
  padding: 0;
}

.myui-vodlist__text.striped .striped-head {
  padding: 10px;
  border-radius: 5px;
}

.myui-vodlist__text .striped-head {
  font-weight: 700;
}

.myui-vodlist__text.to-color li {
  padding: 10px;
  border-radius: 5px;
}

.myui-vodlist__text.to-color li a {
  padding: 0;
}

.myui-vodlist__text.downlist {
  padding-top: 0;
}

.myui-vodlist__text.downlist li {
  padding: 10px 0;
}

.myui-vodlist__text.downlist li .text {
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}

.myui-vodlist__text.downlist li a {
  display: inline-block;
  padding: 0;
}

.myui-vodlist__text.downlist li a.btn {
  padding: 4px 12px;
  margin-left: 10px;
}

/* media-list */

.myui-vodlist__media li:first-child {
  padding-top: 0;
}

.myui-vodlist__media li:last-child {
  padding-bottom: 0;
}

.myui-vodlist__media .thumb,
.myui-vodlist__media .detail {
  display: table-cell;
  vertical-align: top;
}

.myui-vodlist__media .detail {
  width: 100%;
  padding: 0 0 0 20px;
}

.myui-vodlist__media .detail .desc {
  height: 40px;
}

.myui-vodlist__media .detail-side {
  padding: 0 10px 0;
}

.myui-vodlist__media .detail-left {
  padding: 0 20px 0 0;
}

.myui-vodlist__media .detail h4.title {
  margin: 10px 0 10px;
  font-size: 18px;
  line-height: 25px;
}

.myui-vodlist__media .detail h5.title {
  font-size: 15px;
  height: 38px;
  line-height: 20px;
}

/* screen-list */

.myui-vodlist__screen {
  padding: 10px 0 5px;
}

.myui-vodlist__screen:first-child {
  padding-top: 0;
}

.myui-vodlist__screen:last-child {
  padding-bottom: 0;
}

.myui-vodlist__screen li {
  float: left;
  width: 25%;
  margin-bottom: 5px;
}

/* rank-list */

.myui-vodlist__rank li {
  margin-bottom: 10px;
}

.myui-vodlist__rank li:last-child {
  margin-bottom: 0;
}

/* link-list */

.myui-link__text li {
  float: left;
  margin-right: 15px;
  margin-bottom: 10px;
}

/* play-list */

.myui-content__list {
  position: relative;
}

.myui-content__list li {
  float: left;
  padding: 10px;
}

.myui-content__list li a {
  display: block;
  padding: 8px;
  text-align: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}

.myui-play__list li {
  float: left;
}

.myui-play__list li a {
  display: block;
  padding: 8px 0;
  text-align: center;
}

/* article */

.myui-article__title {
  margin: 0 0 10px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

.myui-article__sub {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.myui-article__content {
  line-height: 30px;
  font-size: 16px;
}

.myui-article__content img {
  display: block;
  max-width: 100%;
}

.myui-article__content iframe {
  width: 95%!important;
  height: auto;
}

/* screen */

.myui-screen__list {
  position: relative;
}

.myui-screen__list li {
  float: left;
}

.myui-screen__list li a.btn {
  padding: 5px 12px;
  font-size: 14px;
}

/* table */

.myui-table {
  position: relative;
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.myui-table>thead>tr>th {
  vertical-align: bottom;
  border-bottom: 2px solid;
}

.myui-table>tbody>tr>td,
.myui-table>tbody>tr>th,
.myui-table>tfoot>tr>td,
.myui-table>tfoot>tr>th,
.myui-table>thead>tr>td,
.myui-table>thead>tr>th {
  padding: 8px;
  line-height: 25px;
  vertical-align: top;
  border-bottom: 1px solid;
}

/* message */

.styu-message__list {
  position: relative;
  padding: 20px 0;
}

.styu-message__list .avatar {
  float: left;
  width: 60px;
}

.styu-message__list .data {
  margin-left: 60px;
}

.styu-message__list .data .name {
  margin: 0;
  font-size: 16px;
}

.styu-message__list .data .content {
  padding: 5px 0;
  line-height: 20px;
  word-wrap: break-word;
  white-space: normal;
}

.styu-message__list .data .admin,
.styu-message__list .data .content {
  margin: 0;
  font-size: 14px;
}

.styu-message__list.child {
  margin-top: 20px;
  padding-bottom: 0;
}

/* page */

.myui-page {
  margin-bottom: 30px;
}

.myui-page li {
  display: inline-block;
  margin-left: 10px;
}

.myui-page li .num,
.myui-page li a {
  padding: 5px 15px;
}

/* extra */

.myui-extra {
  position: fixed;
  right: 50px;
  bottom: 50px;
}

.myui-extra li {
  position: relative;
  padding: 15px 10px 0;
}

.myui-extra li a {
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
}

/* flickity */

.flickity {
  position: relative;
  white-space: nowrap;
}

.flickity [class^="col-"] {
  float: none !important;
  display: inline-block !important;
}

.flickity.dots {
  padding-bottom: 20px;
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: 0;
}

.flickity-viewport {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.flickity-prev-next-button {
  opacity: 0;
  position: absolute;
  top: 50%;
  width: 25px;
  height: 50px;
  border: none;
  background-color: rgba(0,0,0,.6);
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all ease-out .35s;
  transition: all ease-out .35s;
}

.flickity-prev-next-button.previous {
  left: 0;
  border-radius: 0 6px 6px 0;
}

.flickity-prev-next-button.next {
  right: 0;
  border-radius: 6px 0 0 6px;
}

.flickity-prev-next-button:disabled {
  opacity: 0;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: #fff;
}

.flickity-prev-next-button.no-svg {
  color: #fff;
  font-size: 18px;
}

.flickity-page-dots {
  position: relative;
  text-align: center;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 15px;
  height: 3px;
  margin: 0 5px;
  opacity: .25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.flickity:hover .flickity-prev-next-button {
  opacity: 1;
}

.flickity.active .flickity-prev-next-button {
  margin-top: -25px;
}

.flickity.center .flickity-prev-next-button.previous {
  left: 0;
}

.flickity.center .flickity-prev-next-button.next {
  right: 0;
}

/* slide */

.carousel {
  position: relative;
}

.carousel-control {
  position: absolute;
  z-index: 99;
  top: 0;
  bottom: 0;
  display: block;
  width: 50px;
  height: 100%;
}

.carousel-control.left {
  left: 30px;
}

.carousel-control.right {
  right: 30px;
}

.carousel-control .fa {
  position: absolute;
  top: 50%;
  margin-top: -25px;
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 30px;
  line-height: 50px;
  background-color: rgba(0,0,0,0.6);
  color: #fff;
  border-radius: 50%;
  opacity: 0.5;
}

.carousel-control:hover .fa {
  opacity: 1;
}

.carousel-indicators {
  position: absolute;
  z-index: 101;
}

.carousel-indicators-dots {
  width: 100%;
  bottom: 20px;
  left: 0;
  text-align: center;
}

.carousel-indicators-dots li {
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 3px;
  opacity: .25;
  cursor: pointer;
}

.carousel-indicators-dots li.active {
  opacity: 1;
}

.carousel-indicators-text {
  z-index: 2;
  top: 0;
  bottom: 0;
  right: 150px;
  width: 260px;
  padding: 50px 20px;
  background-color: rgba(32,32,32,.6);
}

.carousel-indicators-text li {
  padding: 10px 0;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  cursor: pointer;
}

.carousel-indicators-text li:last-child {
  border-bottom: 0;
}

.carousel-indicators-text li .title {
  margin: 0;
  color: #ccc;
}

.carousel-indicators-text li .text {
  margin: 0;
  font-size: 12px;
  color: #999;
}

.carousel-indicators-thumb {
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
}

.carousel-indicators-thumb li {
  display: inline-block;
  margin: 0 2px;
  border: 3px solid rgba(255,255,255,.6);
  box-shadow: 0 2px 5px rgba(0,0,0,.1);
  border-radius: 2px;
  overflow: hidden;
}

/* popup */

.popup {
  position: fixed;
  z-index: 1001;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: .5s;
  -o-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  transition: .5s;
}

.popup.left {
  left: -100%;
  top: 0;
}

.popup.right {
  right: -100%;
  top: 0;
}

.popup.top {
  top: -100%;
  left: 0;
}

.popup.bottom {
  bottom: -100%;
  right: 0;
}

.popup-visible.left {
  left: 0;
}

.popup-visible.right {
  right: 0;
}

.popup-visible.top {
  top: 0;
}

.popup-visible.bottom {
  bottom: 0;
}

.popup-head {
  position: relative;
  padding: 10px 20px;
}

.popup-body {
  position: relative;
  padding: 10px;
}

/* embed */

.embed-responsive {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0;
  height: 0;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
  padding-bottom: 75%;
}

/* bootstrap*/

.fade {
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

.tab-content>.tab-pane,
.carousel-inner>.item {
  display: none;
}

.tab-content>.tab-pane.active,
.carousel-inner>.item.active {
  display: block;
}

/* modal */

.modal-open {
  overflow: hidden;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.fade.in {
  opacity: .5;
}

/* autocomplete */

.autocomplete-suggestions {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 9999;
  padding: 10px;
  margin-top: 5px;
  border-radius: 4px;
}

.autocomplete-suggestion,
.autocomplete-suggestions li {
  padding: 5px 0;
  cursor: pointer;
}

.autocomplete-suggestion:first-child {
  border-top: 0;
}

/* mobile-share */

.mobile-share {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: fade-in;
  animation-duration: .5s;
  -webkit-animation: fade-in .5s;
}

.share-weixin {
  background: url("img/share_weixin.png") rgba(0, 0, 0, .8) no-repeat;
  background-position: right top 10px;
  background-size: 80%;
}

.share-other {
  background: url("img/share_other.png") rgba(0, 0, 0, .8) no-repeat;
  background-position: center bottom 10px;
  background-size: 80%;
}

/* taddar */

.myui-nav__tabbar {
  display: none;
}

/* more */

.hidden {
  overflow: hidden !important;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.top-fixed-up {
  margin-top: 0 !important;
}

.top-fixed {
  -webkit-transition: .5s;
  -o-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  transition: .5s;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.margin-0 {
  margin: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}

.margin-t0 {
  margin: 0 !important;
}

.padding-t0 {
  padding-top: 0 !important;
}

.margin-b0 {
  margin-bottom: 0 !important;
}

.padding-b0 {
  padding-top: 0 !important;
}

.radius-0 {
  border-radius: 0 !important;
}

.hide,
.visible-lg,
.visible-md,
.visible-sm,
.visible-xs,
.visible-mi,
.visible-inline-lg,
.visible-inline-md,
.visible-inline-sm,
.visible-inline-xs,
.visible-inline-mi {
  display: none !important;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  animation: fade-in;
  animation-duration: .5s;
  -webkit-animation: fade-in .5s;
}

.fadeInDown {
  -webkit-animation: fadeInDown .5s .2s ease both;
  -moz-animation: fadeInDown .5s .2s ease both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

/* media */

.col-pd,
.col-lg-1,
.col-lg-10,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
}

.col-xs-1,
.col-xs-10,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-wide-1,
.col-xs-wide-10,
.col-xs-wide-15,
.col-xs-wide-2,
.col-xs-wide-25,
.col-xs-wide-3,
.col-xs-wide-35,
.col-xs-wide-4,
.col-xs-wide-45,
.col-xs-wide-5,
.col-xs-wide-55,
.col-xs-wide-6,
.col-xs-wide-65,
.col-xs-wide-7,
.col-xs-wide-75,
.col-xs-wide-8,
.col-xs-wide-85,
.col-xs-wide-9,
.col-xs-wide-95 {
  float: left;
}

.col-xs-10 {
  width: 10%;
}

.col-xs-9 {
  width: 11.1111111%;
}

.col-xs-8 {
  width: 12.5%;
}

.col-xs-7 {
  width: 14.2857143%;
}

.col-xs-6 {
  width: 16.6666667%;
}

.col-xs-5 {
  width: 20%;
}

.col-xs-4 {
  width: 25%;
}

.col-xs-3 {
  width: 33.3333333%;
}

.col-xs-2 {
  width: 50%;
}

.col-xs-1 {
  width: 100%;
}

.col-xs-wide-10 {
  width: 10%;
}

.col-xs-wide-9 {
  width: 90%;
}

.col-xs-wide-8 {
  width: 80%;
}

.col-xs-wide-7 {
  width: 70%;
}

.col-xs-wide-6 {
  width: 60%;
}

.col-xs-wide-5 {
  width: 50%;
}

.col-xs-wide-4 {
  width: 40%;
}

.col-xs-wide-3 {
  width: 30%;
}

.col-xs-wide-2 {
  width: 20%;
}

.col-xs-wide-15 {
  width: 15%;
}

.col-xs-wide-95 {
  width: 95%;
}

.col-xs-wide-85 {
  width: 85%;
}

.col-xs-wide-75 {
  width: 75%;
}

.col-xs-wide-65 {
  width: 65%;
}

.col-xs-wide-55 {
  width: 55%;
}

.col-xs-wide-45 {
  width: 45%;
}

.col-xs-wide-35 {
  width: 35%;
}

.col-xs-wide-25 {
  width: 25%;
}

.img-xs-300 {
  width: 300px;
}

.img-xs-220 {
  width: 220px;
}

.img-xs-190 {
  width: 190px;
}

.img-xs-160 {
  width: 160px;
}

.img-xs-150 {
  width: 150px;
}

.img-xs-140 {
  width: 140px;
}

.img-xs-130 {
  width: 130px;
}

.img-xs-120 {
  width: 120px;
}

.img-xs-110 {
  width: 110px;
}

.img-xs-100 {
  width: 100px;
}

.img-xs-90 {
  width: 90px;
}

.img-xs-80 {
  width: 80px;
}

.img-xs-70 {
  width: 70px;
}

@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-10,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-wide-1,
  .col-sm-wide-10,
  .col-sm-wide-15,
  .col-sm-wide-2,
  .col-sm-wide-25,
  .col-sm-wide-3,
  .col-sm-wide-35,
  .col-sm-wide-4,
  .col-sm-wide-45,
  .col-sm-wide-5,
  .col-sm-wide-55,
  .col-sm-wide-6,
  .col-sm-wide-65,
  .col-sm-wide-7,
  .col-sm-wide-75,
  .col-sm-wide-8,
  .col-sm-wide-85,
  .col-sm-wide-9,
  .col-sm-wide-95 {
    float: left;
  }

  .col-sm-10 {
    width: 10%;
  }

  .col-sm-9 {
    width: 11.1111111%;
  }

  .col-sm-8 {
    width: 12.5%;
  }

  .col-sm-7 {
    width: 14.2857143%;
  }

  .col-sm-6 {
    width: 16.6666667%;
  }

  .col-sm-5 {
    width: 20%;
  }

  .col-sm-4 {
    width: 25%;
  }

  .col-sm-3 {
    width: 33.3333333%;
  }

  .col-sm-2 {
    width: 50%;
  }

  .col-sm-1 {
    width: 100%;
  }

  .col-sm-wide-10 {
    width: 10%;
  }

  .col-sm-wide-9 {
    width: 90%;
  }

  .col-sm-wide-8 {
    width: 80%;
  }

  .col-sm-wide-7 {
    width: 70%;
  }

  .col-sm-wide-6 {
    width: 60%;
  }

  .col-sm-wide-5 {
    width: 50%;
  }

  .col-sm-wide-4 {
    width: 40%;
  }

  .col-sm-wide-3 {
    width: 30%;
  }

  .col-sm-wide-2 {
    width: 20%;
  }

  .col-sm-wide-15 {
    width: 15%;
  }

  .col-sm-wide-95 {
    width: 95%;
  }

  .col-sm-wide-85 {
    width: 85%;
  }

  .col-sm-wide-75 {
    width: 75%;
  }

  .col-sm-wide-65 {
    width: 65%;
  }

  .col-sm-wide-55 {
    width: 55%;
  }

  .col-sm-wide-45 {
    width: 45%;
  }

  .col-sm-wide-35 {
    width: 35%;
  }

  .col-sm-wide-25 {
    width: 25%;
  }

  .img-sm-300 {
    width: 300px;
  }

  .img-sm-220 {
    width: 220px;
  }

  .img-sm-190 {
    width: 190px;
  }

  .img-sm-160 {
    width: 160px;
  }

  .img-sm-150 {
    width: 150px;
  }

  .img-sm-140 {
    width: 140px;
  }

  .img-sm-130 {
    width: 130px;
  }

  .img-sm-120 {
    width: 120px;
  }

  .img-sm-110 {
    width: 110px;
  }

  .img-sm-100 {
    width: 100px;
  }

  .img-sm-90 {
    width: 90px;
  }

  .img-sm-80 {
    width: 80px;
  }

  .img-sm-70 {
    width: 70px;
  }
}

@media (min-width: 1024px) {
  .col-md-1,
  .col-md-10,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-wide-1,
  .col-md-wide-10,
  .col-md-wide-15,
  .col-md-wide-2,
  .col-md-wide-25,
  .col-md-wide-3,
  .col-md-wide-35,
  .col-md-wide-4,
  .col-md-wide-45,
  .col-md-wide-5,
  .col-md-wide-55,
  .col-md-wide-6,
  .col-md-wide-65,
  .col-md-wide-7,
  .col-md-wide-75,
  .col-md-wide-8,
  .col-md-wide-85,
  .col-md-wide-9,
  .col-md-wide-95 {
    float: left;
  }

  .col-md-10 {
    width: 10%;
  }

  .col-md-9 {
    width: 11.1111111%;
  }

  .col-md-8 {
    width: 12.5%;
  }

  .col-md-7 {
    width: 14.2857143%;
  }

  .col-md-6 {
    width: 16.6666667%;
  }

  .col-md-5 {
    width: 20%;
  }

  .col-md-4 {
    width: 25%;
  }

  .col-md-3 {
    width: 33.3333333%;
  }

  .col-md-2 {
    width: 50%;
  }

  .col-md-1 {
    width: 100%;
  }

  .col-md-wide-10 {
    width: 10%;
  }

  .col-md-wide-9 {
    width: 90%;
  }

  .col-md-wide-8 {
    width: 80%;
  }

  .col-md-wide-7 {
    width: 70%;
  }

  .col-md-wide-6 {
    width: 60%;
  }

  .col-md-wide-666 {
    width: 71%;
  }

  .col-md-wide-5 {
    width: 50%;
  }

  .col-md-wide-4 {
    width: 40%;
  }

  .col-md-wide-3 {
    width: 30%;
  }

  .col-md-wide-2 {
    width: 20%;
  }

  .col-md-wide-15 {
    width: 15%;
  }

  .col-md-wide-95 {
    width: 95%;
  }

  .col-md-wide-85 {
    width: 85%;
  }

  .col-md-wide-75 {
    width: 75%;
  }

  .col-md-wide-65 {
    width: 65%;
  }

  .col-md-wide-55 {
    width: 55%;
  }

  .col-md-wide-45 {
    width: 45%;
  }

  .col-md-wide-35 {
    width: 35%;
  }

  .col-md-wide-25 {
    width: 25%;
  }

  .img-md-300 {
    width: 300px;
  }

  .img-md-220 {
    width: 220px;
  }

  .img-md-190 {
    width: 190px;
  }

  .img-md-160 {
    width: 160px;
  }

  .img-md-150 {
    width: 150px;
  }

  .img-md-140 {
    width: 140px;
  }

  .img-md-130 {
    width: 130px;
  }

  .img-md-120 {
    width: 120px;
  }

  .img-md-110 {
    width: 110px;
  }

  .img-md-100 {
    width: 100px;
  }

  .img-md-90 {
    width: 90px;
  }

  .img-md-80 {
    width: 80px;
  }

  .img-md-70 {
    width: 70px;
  }
}

@media (min-width: 1440px) {
  .col-lg-1,
  .col-lg-10,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-wide-1,
  .col-lg-wide-10,
  .col-lg-wide-15,
  .col-lg-wide-2,
  .col-lg-wide-25,
  .col-lg-wide-3,
  .col-lg-wide-35,
  .col-lg-wide-4,
  .col-lg-wide-45,
  .col-lg-wide-5,
  .col-lg-wide-55,
  .col-lg-wide-6,
  .col-lg-wide-65,
  .col-lg-wide-7,
  .col-lg-wide-75,
  .col-lg-wide-8,
  .col-lg-wide-85,
  .col-lg-wide-9,
  .col-lg-wide-95 {
    float: left;
  }

  .col-lg-10 {
    width: 10%;
  }

  .col-lg-9 {
    width: 11.1111111%;
  }

  .col-lg-8 {
    width: 12.5%;
  }

  .col-lg-7 {
    width: 14.2857143%;
  }

  .col-lg-6 {
    width: 16.6666667%;
  }

  .col-lg-5 {
    width: 20%;
  }

  .col-lg-4 {
    width: 25%;
  }

  .col-lg-3 {
    width: 33.3333333%;
  }

  .col-lg-2 {
    width: 50%;
  }

  .col-lg-1 {
    width: 100%;
  }

  .col-lg-wide-10 {
    width: 10%;
  }

  .col-lg-wide-9 {
    width: 90%;
  }

  .col-lg-wide-8 {
    width: 80%;
  }

  .col-lg-wide-7 {
    width: 70%;
  }

  .col-lg-wide-6 {
    width: 60%;
  }

  .col-lg-wide-5 {
    width: 50%;
  }

  .col-lg-wide-4 {
    width: 40%;
  }

  .col-lg-wide-3 {
    width: 30%;
  }

  .col-lg-wide-2 {
    width: 20%;
  }

  .col-lg-wide-15 {
    width: 15%;
  }

  .col-lg-wide-95 {
    width: 95%;
  }

  .col-lg-wide-85 {
    width: 85%;
  }

  .col-lg-wide-75 {
    width: 75%;
  }

  .col-lg-wide-65 {
    width: 65%;
  }

  .col-lg-wide-55 {
    width: 55%;
  }

  .col-lg-wide-45 {
    width: 45%;
  }

  .col-lg-wide-35 {
    width: 35%;
  }

  .col-lg-wide-25 {
    width: 25%;
  }

  .img-lg-300 {
    width: 300px;
  }

  .img-lg-220 {
    width: 220px;
  }

  .img-lg-190 {
    width: 190px;
  }

  .img-lg-160 {
    width: 160px;
  }

  .img-lg-150 {
    width: 150px;
  }

  .img-lg-140 {
    width: 140px;
  }

  .img-lg-130 {
    width: 130px;
  }

  .img-lg-120 {
    width: 120px;
  }

  .img-lg-110 {
    width: 110px;
  }

  .img-lg-100 {
    width: 100px;
  }

  .img-lg-90 {
    width: 90px;
  }

  .img-lg-80 {
    width: 80px;
  }

  .img-lg-70 {
    width: 70px;
  }
}

@media (min-width: 1600px) {
  .visible-lg {
    display: block !important;
  }

  .hidden-lg {
    display: none !important;
  }

  .visible-inline-lg {
    display: inline-block !important;
  }

  .visible-text-lg {
    visibility: visible !important;
  }

  .hidden-text-lg {
    visibility: hidden !important;
  }
}

@media (max-width: 1199px) and (min-width: 1023px) {
  .visible-md {
    display: block !important;
  }

  .hidden-md {
    display: none!important;
  }

  .visible-inline-md {
    display: inline-block !important;
  }

  .visible-text-md {
    visibility: visible !important;
  }

  .hidden-text-md {
    visibility: hidden !important;
  }
}

@media (max-width: 1023px) and (min-width: 767px) {
  .visible-sm {
    display: block !important;
  }

  .hidden-sm {
    display: none !important;
  }

  .visible-inline-sm {
    display: inline-block !important;
  }

  .visible-text-sm {
    visibility: visible !important;
  }

  .hidden-text-sm {
    visibility: hidden !important;
  }
}

@media (max-width: 1023px) {
  .dropdown-hover:hover .dropdown-box {
    display: none;
  }

  /* panel-head */

  .myui-panel__head .more {
    line-height: 35px;
  }

  .myui-panel__head.active {
    height: 35px;
  }

  /* nav */

  .nav-head > li,
  .nav-tabs > li {
    margin-top: 8px;
    margin-left: 15px;
  }

  .nav-tabs.active {
    height: 35px;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .nav-tabs.active li {
    float: none;
    display: inline-block;
  }

  .nav-tabs > li .dropdown-box {
    margin-left: -80px;
  }

  /* screen */

  .myui-screen__list {
    white-space: nowrap;
    overflow-y: hidden;
  }

  .myui-screen__list li {
    float: none;
    display: inline-block;
    margin-bottom: 0;
  }

  .myui-screen__list li a.btn {
    font-size: 12px;
    white-space: nowrap;
  }

  /* vod-list */

  .myui-vodlist__detail .title.active {
    font-size: 14px;
    margin-bottom: 0;
  }

  /* play-list */

  .myui-content__playlist {
    -webkit-overflow-scrolling: touch;
  }

  /* modal */

  .myui-modal__dialog {
    width: 320px;
    margin: 50px auto;
  }

@media (max-width: 767px) {
    .visible-xs {
      display: block !important;
    }

    .hidden-xs {
      display: none!important;
    }

    .visible-inline-xs {
      display: inline-block !important;
    }

    .visible-text-xs {
      visibility: visible !important;
    }

    .hidden-text-xs {
      visibility: hidden !important;
    }

    /* all */

    body {
      font-size: 12px;
    }

    /* text */

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 18px;
    }

    h4 {
      font-size: 16px;
    }

    h5 {
      font-size: 14px;
    }

    h6 {
      font-size: 12px;
    }

    /* btn */

    .btn {
      padding: 8px 16px;
    }

    .btn.btn-xs {
      padding: 4px 8px;
    }

    .btn.btn-sm {
      padding: 6px 12px;
    }

    .btn.btn-md {
      padding: 8px 16px;
    }

    .btn.btn-lg {
      padding: 12px 24px;
    }

    /* tabbar */

    .myui-nav__tabbar {
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 5px 0 0;
      z-index: 999;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      border-top: 1px solid;
    }

    .myui-nav__tabbar .item {
      display: block;
      text-align: center;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
    }

    .myui-nav__tabbar .item .icon {
      display: block;
      text-align: center;
      font-size: 20px;
      line-height: 20px;
    }

    .myui-nav__tabbar .item .icon-img {
      display: inline-block;
      margin-top: 5px;
      width: 24px;
      height: 24px;
    }

    .myui-nav__tabbar .item .title {
      margin: 5px 0;
      font-size: 12px;
    }

    /* line */

    .split-line {
      height: 12px;
      margin-left: 5px;
      margin-right: 5px;
    }

    .top-line:before,
    .top-line-dot:before {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }

    .bottom-line:after,
    .bottom-line-dot:before {
      -webkit-transform-origin: 0 100%;
      transform-origin: 0 100%;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }

    /* panel-head */

    .myui-panel__head .title,
    .myui-panel__head .title a {
      font-size: 18px;
    }

    /* vod-list */

    .myui-vodlist__thumb .tag {
      padding: 0 5px;
    }

    .myui-vodlist__thumb .silde-title {
      font-size: 12px;
    }

    .myui-vodlist__detail .title,
    .myui-vodlist__detail .title.active {
      margin-bottom: 0;
      font-size: 12px;
    }

    /* media-list */

    .myui-vodlist__media li .detail.active {
      padding-left: 0;
      padding-right: 10px;
    }

    .myui-vodlist__media .detail h4.title {
      margin-top: 0;
      font-size: 14px;
      line-height: 18px;
    }

    /* text-list */

    .myui-vodlist__text li a {
      padding: 10px 0;
    }

    .myui-vodlist__text.downlist li {
      padding: 5px 0 0;
    }

    /* article */

    .myui-article__title {
      font-size: 18px;
    }

    /* page */

    .myui-page li {
      float: left;
      width: 20%;
      margin: 0;
      padding: 0 5px 0 5px;
    }

    .myui-page li a {
      display: block;
      padding: 5px 0;
      text-align: center;
    }

    /* extra */

    .myui-extra li {
      padding: 8px 0 0;
    }

    .myui-extra li a {
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: 16px;
    }

    /* flickity */

    .flickity-prev-next-button {
      width: 20px;
      height: 40px;
    }

    .flickity-prev-next-button.previous {
      border-radius: 0 5px 5px 0;
    }

    .flickity-prev-next-button.next {
      border-radius: 5px 0 0 5px;
    }

    /* slide */

    .carousel-control {
      width: 30px;
      height: 100%;
    }

    .carousel-control .fa {
      margin-top: -15px;
      display: block;
      width: 30px;
      height: 30px;
      font-size: 20px;
      line-height: 30px;
    }

    .carousel-control.left {
      left: 10px;
    }

    .carousel-control.right {
      right: 10px;
    }

    /* more */

    .m-margin-0 {
      margin: 0 !important;
    }

    .m-padding-0 {
      padding: 0 !important;
    }

    .mac_pop {
      display: none;
      width: 95%!important;
      top: 20%;
      right: 0;
      margin: auto;
      padding: 25px;
      z-index: 130;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0 3px 18px rgba(0,0,0,.5);
      font-size: .8rem;
    }
}
}

/*ç•™è¨€*/

.gbook-wrapper {
  color: #666;
  padding: 0 5px;
}

.yookou-page-info {
  text-align: center;
}

.yookou-rims-info {
  background: #fff;
}

.top65 {
  margin-top: 20px;
}

.yookou-font-xvi {
  font-size: 18px;
  margin-bottom: 20px;
}

.ling p {
  line-height: 20px;
}

.yookou-list-head {
  height: 40px;
}

.yookou-list-head h2 {
  font-size: 18px;
  float: left;
  line-height: 40px;
}

.yookou-part-right {
  float: right;
  line-height: 40px;
}

.yookou-form-info {
  padding: 0 10px;
  width: 100%!important;
  height: 40px;
  line-height: 40px;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  background-color: #f5f5f5;
}

.yookou-rims-info-remain {
  border: none;
  padding: 0;
  background: none;
  color: #666;
}

.gbook_remaining {
  color: #1296db;
}

.yookou-form-area {
  padding: 10px;
  line-height: 1.25rem;
}

textarea {
  overflow: hidden;
  height: auto!important;
}

.yookou-col-md4 {
  width: 33.33333333%;
  float: left;
}

.yookou-col-md2 {
  float: left;
  width: 16.66666667%;
  text-align: center;
}

.mod [class*=col-] {
  padding: 10px;
}

.gbook_submit {
  outline: none;
  float: right;
  background-color: #1296db!important;
  border: solid 1px #1296db!important;
  color: #fff!important;
  height: 40px!important;
  border-radius: 3px!important;
}

.yookou-comm-code {
  width: 100%;
}

.yookou-list-head {
  overflow: hidden;
}

.yookou-comm-each {
  padding: 20px 0;
  border-top: solid 1px #e2e2e2;
  overflow: hidden;
}

.yookou-comm-list .yookou-comm-avat {
  float: left;
  width: 40px;
  height: 40px;
}

.yookou-comm {
  float: left;
  overflow: hidden;
  padding-left: 15px;
  width: 92%;
}

.yookou-comm-head {
  overflow: hidden;
}

.yookou-comm-cont p {
  margin: 0;
  font-size: 12px;
  margin-top: 5px;
  text-align: left;
}

.yookou-comm-reply {
  background-color: #f7f7f7;
  padding: 15px;
  overflow: hidden;
  margin-top: 10px;
}

.yookou-comm-head .yookou-text-line {
  float: left;
}

.yookou-comm-head .yookou-part-tips {
  float: right;
}

.yookou-comm-cont {
  text-align: left;
  color: #666;
  line-height: 20px;
}

.yookou-comm-left {
  float: left;
  overflow: hidden;
  margin-left: 15px;
  width: 94%;
}

/*æŠ¥é”™*/

.reply_box form {
  padding: 15px;
}

.reply_box form .msg_cue {
  margin-bottom: 8px;
}

.reply_box form textarea {
  width: 100%;
  padding: 10px;
  overflow-y: auto;
  box-sizing: border-box;
  border: solid 1px #e2e2e2;
  border-radius: 3px;
}

.reply_box .msg_code {
  margin: 10px 0;
}

.reply_box .msg_code input {
  border: 1px solid #ddd;
  padding: 4px;
  width: 32%;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.reply_box .msg_code .mac_verify_img {
  height: 40px;
  margin-left: 2px;
  vertical-align: top;
  float: none;
  margin-top: 0;
  width: 32%;
}

.reply_box .msg_code .gbook_submit {
  width: 32%;
  height: 32px;
  line-height: 32px;
  margin-top: 0;
  cursor: pointer;
}

.index-float-qrcode-bg,
.index-float-qrcode {
  position: fixed;
  z-index: 9999;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
}

.index-float-qrcode-bg {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.3);
}

/* gbook page */

.mod-pagination {
  text-align: center;
  font-size: 0;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.mod-pagination>ul>li {
  display: inline-block;
  margin: 30px 0;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.mod-pagination>ul>li.active>a,
.mod-pagination>ul>li.active>span,
.mod-pagination>ul>li.page-active>a,
.mod-pagination>ul>li.page-active>span {
  background-color: #1296db;
  color: #fff;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}

.mod-pagination>ul>li>a,
.mod-pagination>ul>li>span {
  cursor: pointer;
  text-align: center;
  border-radius: 3px;
  background-color: #fff;
  font-size: 14px;
  margin: 0 5px;
  padding: 0 14px;
  display: inline-block;
  height: 32px;
  line-height: 32px;
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #777;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

/*报错-留言*/

/*弹出层*/

.mac_pop_bg {
  position: fixed;
  z-index: 129;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.2);
}

.mac_pop {
  z-index: 99998;
  display: none;
  min-height: 20px;
  max-height: 750px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  padding: 25px;
  z-index: 130;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 3px 18px rgba(0, 0, 0, .5);
}

.mac_pop .pop_top {
  height: 40px;
  width: 100%;
  border-bottom: 1px #E5E5E5 solid;
}

.mac_pop .pop_top h2 {
  float: left;
  display: block;
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  font-family: "microsoft yahei";
}

.mac_pop span.pop_close {
  float: right;
  width: 23px;
  height: 23px;
  font-size: 0;
  text-indent: 9999;
  cursor: pointer;
  font-weight: bold;
  display: block;
  background: url("img/background.png") -10px 0 no-repeat;
}

.mac_pop .pop-foot {
  height: 50px;
  line-height: 50px;
  width: 100%;
  border-top: 1px #E5E5E5 solid;
  text-align: right;
}

.mac_pop .pop-cancel,
.pop-ok {
  padding: 8px 15px;
  margin: 15px 5px;
  border: none;
  border-radius: 5px;
  background-color: #337AB7;
  color: #fff;
  cursor: pointer;
}

.mac_pop .pop-cancel {
  background-color: #FFF;
  border: 1px #CECECE solid;
  color: #000;
}

.mac_pop .pop-content {
  height: 380px;
}

.mac_pop .pop-content-left {
  float: left;
}

.mac_pop .pop-content-right {
  width: 310px;
  float: left;
  padding-top: 20px;
  padding-left: 20px;
  font-size: 16px;
  line-height: 35px;
}

.mac_pop .bgPop {
  display: none;
  position: absolute;
  z-index: 129;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.2);
}

.mac_pop .pop-msg {
  text-align: center;
  font-size: 14px;
}

.mac_pop_msg_bg {
  position: fixed;
  z-index: 129;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.2);
}

.mac_pop_msg {
  z-index: 99999;
  display: none;
  min-height: 20px;
  max-height: 750px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  padding: 25px;
  z-index: 130;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 3px 18px rgba(0, 0, 0, .5);
}

.mac_pop_msg .pop-msg {
  text-align: center;
  font-size: 14px;
}

/*登录注册*/

.mod-login {
  width: 400px;
  padding: 40px;
  background: #FFF;
  margin: 80px auto;
}

.mod-login .content h2 {
  line-height: 22px;
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
}

.mod-login .content p.tips {
  text-align: center;
  line-height: 20px;
  letter-spacing: 4px;
  color: #999;
  margin-bottom: 20px;
}

.mod-login .error {
  padding: 16px;
  line-height: 20px;
  background: #FBE1E3;
  border-radius: 3px;
  margin-bottom: 20px;
  color: #FE6C69;
}

.mod-login .input-group {
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}

.mod-login .input-group .iconfont {
  width: 18px;
  height: 18px;
  display: block;
  float: left;
  position: absolute;
  left: 15px;
  top: 15px;
  color: #DDD;
  text-align: center;
  line-height: 18px;
  vertical-align: middle;
}

.mod-login .input-group .input {
  width: 100%;
  height: 48px;
  line-height: 18px;
  border: 2px solid #F5F5F5;
  padding: 13px 15px;
  border-radius: 3px;
  padding-left: 43px;
}

.mod-login .input-group.input-code .input {
  padding-right: 124px;
}

.mod-login .input-group.input-code .code-img,
.mod-login .input-group.input-code .code-message {
  position: absolute;
  width: 100px;
  height: 44px;
  right: 0;
  top: 2px;
}

.mod-login .input-group.input-code .code-img:after,
.mod-login .input-group.input-code .code-message:after {
  content: "";
  width: 2px;
  height: 12px;
  background: #F5F5F5;
  display: block;
  position: absolute;
  left: -12px;
  top: 16px;
}

.mod-login .input-group.input-code .code-message button:disabled {
  color: #999;
  pointer-events: none;
  cursor: not-allowed;
}

.mod-login .input-group.input-code .code-message {
  width: 140px;
}

.mod-login .input-group.input-code .code-message button {
  line-height: 44px;
  color: #666;
  text-align: center;
  cursor: pointer;
  background: 0;
  width: 140px;
}

.mod-login .option {
  height: 18px;
  line-height: 18px;
  font-size: 14px;
}

.mod-login .option .label {
  padding-left: 28px;
  position: relative;
  height: 18px;
  line-height: 18px;
  cursor: pointer;
}

.mod-login .option .label:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background: #EEE;
  position: absolute;
  left: 5px;
  top: 6px;
  z-index: 9;
}

.mod-login .option .label:after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  left: 0;
  top: 1px;
  border-radius: 100px;
  border: 2px solid #EEE;
  display: block;
}

.mod-login .option .checkBox:checked+.label:after {
  border: 2px solid #ff6600;
}

.mod-login .option .checkBox:checked+.label:before {
  background: #ff6600;
}

.mod-login .bottom {
  margin-top: 20px;
}

.mod-login .bottom .button {
  width: 100%;
  height: 45px;
  line-height: 45px;
  cursor: pointer;
  text-align: center;
  background: #5bb7fe;
  letter-spacing: 4px;
  display: block;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
}

.mod-login .bottom .button:disabled {
  background: #F5F5F5;
  pointer-events: none;
  cursor: not-allowed;
  color: #999;
}

.mod-login .bottom .button:hover {
  opacity: .9;
}

.mod-login .bottom p {
  text-align: center;
  width: 100%;
  height: 18px;
  line-height: 18px;
  margin: 16px 0;
  color: #999;
}

.mod-login .bottom .button.reg {
  background: #363636;
}

.mod-login .quick-login {
  margin-top: 60px;
}

.mod-login .quick-login p {
  width: 100%;
  height: 18px;
  line-height: 18px;
  font-size: 14px;
  text-align: center;
  color: #666;
}

.mod-login .quick-login .contact {
  height: 40px;
  width: 100%;
  margin-top: 20px;
  text-align: center;
  font-size: 0;
}

.mod-login .quick-login .contact a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: cover;
  margin: 0 10px;
}

.mod-login .quick-login .contact a.qq {
  background-image: url("images/TB2Wy5ymH9YBuNjy0FgXXcxcXXa_!!1823360683.png");
}

.mod-login .quick-login .contact a.weixin {
  background-image: url("images/TB2Tc80mN1YBuNjy1zcXXbNcXXa_!!1823360683.png");
}

.mod-login .quick-login .contact a.sina {
  background-image: url("images/TB2.LUeelyWBuNkSmFPXXXguVXa_!!1823360683.png");
}

.mod-login .other {
  margin-top: 40px;
  height: 18px;
  text-align: center;
  width: 100%;
  line-height: 18px;
}

.mod-login .other span {
  display: inline-block;
  margin: 0 20px;
  width: 1px;
  height: 12px;
  background: #F6F6F6;
  vertical-align: middle;
}

.mod-login .input-group .Validform_checktip {
  display: block;
  height: 20px;
  line-height: 20px;
  color: #ff6600;
  font-size: 12px;
  display: none;
  margin-top: 5px;
}

.mod-login .input-group .Validform_checktip.Validform_wrong {
  display: block;
}

/*搜索为空*/

.no_data_div {
  display: table;
  margin: 80px auto;
}

.no_data {
  display: table-cell;
  width: 100%;
  text-align: center;
  vertical-align: middle;
}

.no_data p {
  font-size: 14px;
  line-height: 20px;
  margin: 20px 0;
  color: #333;
}

/*新加*/

.weeks {
  width: 100%;
  background-color: #fff;
}

.weeks .weekItem {
  width: 100%;
  height: 2.5rem;
  border-radius: .34133rem;
  margin: 0 auto;
  line-height: 2.5rem;
  border: 1px solid #e5e9ef;
}

.weeks .weekItem li {
  float: left;
  height: 1.9rem;
  width: 14%;
  line-height: 1.9rem;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  border-radius: 1rem;
  font-size: 1rem;
  margin-top: 0.3rem;
}

.weeks .weekItem li a {
  height: 1.9rem;
  width: 100%;
  line-height: 1.9rem;
  text-align: center;
  display: block;
}

.weeks .weekbox {
  border: 1px solid #e5e9ef;
  border-radius: .34133rem;
  margin-top: .35rem;
}

.weeks .weekbox .box01_c {
  height: 18rem;
  overflow: auto;
}

.weeks .weekbox .box01_c div {
  position: relative;
  float: left;
}

.weeks .weekbox .box01_c .week_div {
  margin-top: 10px;
  width: 100%;
}

.weeks .weekbox .box01_c div dl.active {
  display: block;
}

.weeks .weekbox .box01_c div dl {
  padding: 0 .1rem 0.1rem;
  line-height: 1.15rem;
  position: relative;
  width: 100%;
  overflow: hidden;
  display: none;
}

.weeks .weekbox .box01_c div dl dt {
  color: #999;
  padding-bottom: .25rem;
}

@media (min-width: 1024px) {

}

.item-week .item-link {
  width: 35%;
  height: 8rem;
  float: left;
  margin-bottom: 1.88267rem;
  display: block;
  margin-right: .32933rem;
}

.item-week .item-link-a {
  width: 100%;
  float: left;
  margin-bottom: 1.88267rem;
  display: block;
  margin-right: .32933rem;
  line-height: 1.5rem;
  font-size: 0.9rem;
}

.item-week .title {
  width: 60%;
  height: 3rem;
  display: block;
  position: relative;
  margin-top: 0.21333rem;
  line-height: 2rem;
  overflow: hidden;
  font-size: .525rem;
}

.item-week .week_update {
  display: block;
  margin-top: 21%;
  color: #00a1d6;
}

.weeks .weekItem li.hover,
.weeks .weekItem li.active {
  background: #fb7299;
  color: #fff;
}

.weeks .weekItem li.hover,
.weeks .weekItem li.active a {
  color: #fff;
}

.item-week .item-imgContainer {
  width: 100%;
  height: 8rem;
  overflow: hidden;
  display: block;
  margin: auto;
  border-radius: .256rem;
  -webkit-border-radius: .256rem;
  background-color: #e7e7e7;
  background-position: 50% 50% !important;
  background-size: cover !important;
}

.lazy-thumb {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: block;
}

@media (max-width: 767px) {
  .item-week .item-link {
    height: 6rem;
  }

  .item-week .item-imgContainer {
    height: 6rem;
  }

  .item-week .item-link {
    width: 40%;
  }

  .item-week .title {
    width: 55%;
  }

  .item-week .week_update {
    margin-top: 0%;
  }
}